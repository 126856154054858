import { defaultHeaders, defaultParamsSerializer, defaultTimeout, defaultValidateStatus } from './defaults';
import { FetchArgs, Method, ResponseHandler } from './types';

import { concatParams, isJsonifiable } from ':utils';

/**
 * @description Обработка ответа
 */
const handleResponse = (response: Response, responseHandler: ResponseHandler) => {
  if (!responseHandler) {
    return response;
  }

  if (typeof responseHandler === 'function') {
    return responseHandler(response);
  }

  return response[responseHandler]();
};

/**
 * @description Расширение функциональности fetch
 */
export const fetchFn = (method: Method) => async (arg: string | FetchArgs) => {
  const resolvedArg = typeof arg === 'string' ? { url: arg } : arg;
  const {
    validateStatus = defaultValidateStatus,
    params = undefined,
    headers = new Headers(defaultHeaders),
    paramsSerializer = defaultParamsSerializer,
    responseHandler = 'json',
    url,
    body,
    timeout = defaultTimeout,
    ...rest
  } = resolvedArg;

  const controller = new AbortController();

  const resolvedBody = isJsonifiable(body) ? JSON.stringify(body) : body;

  const config: RequestInit = {
    ...rest,
    headers,
    method,
    body: resolvedBody,
    signal: controller.signal,
  };

  const resolvedUrl = params ? concatParams(url, paramsSerializer(params)) : url;

  const request = new Request(resolvedUrl, config);

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);

  let response: Response;

  try {
    response = await fetch(request);
  } finally {
    clearTimeout(timeoutId);
  }

  if (!validateStatus(response)) {
    return Promise.reject(response);
  }

  return handleResponse(response, responseHandler);
};
