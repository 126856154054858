import { ChangeEvent, FC, useState } from 'react';
import { Button, Card, Checkbox, Icon, Option, Search, Select, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import cn from 'clsx';

import styles from './styles.module.scss';

import { BCity, BDirection, BRole, BWorkFormat, BWorkGrade } from ':types';
import { CheckboxTree } from ':components';

interface Props {
  /** Доступные города */
  cities: BCity[];
  /** Стиль */
  className?: string;
  /** Доступные направления */
  directions: BDirection[];
  /** Отключения сброса фильтров */
  disableReset: boolean;
  /** Галочка ограниченных возможностей */
  isDisability: boolean;
  /** Галочка стажировки */
  isInternship: boolean;
  /** Callback нажатия на checkbox */
  onCheckboxChange: (key: string) => (event: ChangeEvent<HTMLInputElement>) => void;
  /** Callback нажатия на TreeNode */
  onCheckboxTreeChange: (roleIds: string[]) => void;
  /** Callback нажатия на сброс фильтров */
  onResetFilters: () => void;
  /** Callback выбора в Select */
  onSelectChange: (key: string) => (data: BCity[] | BWorkGrade[] | BWorkFormat[]) => void;
  /** Id элемента для рендера в модалке */
  rootElementId?: string;
  /** Выбранные города */
  selectedCities: BCity[];
  /** Выбранный опыт работы */
  selectedGrades: BWorkGrade[];
  /** Выбранные направления */
  selectedRoles: BRole[];
  /** Выбранный формат работы */
  selectedWorkFormat: BWorkFormat[];
  /** Формат работы */
  workFormats: BWorkFormat[];
  /** Опыт работы */
  workGrade: BWorkGrade[];
}

/** Компонент карточки с фильтрами для каталога вакансий  */
export const FilterCard: FC<Props> = ({
  selectedCities,
  selectedGrades,
  selectedWorkFormat,
  isInternship,
  isDisability,
  cities,
  workGrade,
  workFormats,
  onSelectChange: handleSelectChange,
  onCheckboxChange: handleCheckboxChange,
  onCheckboxTreeChange: handleCheckboxTreeChange,
  directions,
  selectedRoles,
  disableReset,
  onResetFilters: handleResetFilters,
  rootElementId,
  className,
}) => {
  const [roleSearch, setRoleSearch] = useState(false);
  const [roleSearchValue, setRoleSearchValue] = useState('');

  const onMakeOptionCity = ({ name, display_region, vacancies_number }: BCity) => {
    return (
      <div className={styles.city}>
        <div className={styles.cityName}>
          <Typography variant="body2">{name}</Typography>
          {display_region && (
            <Typography className={styles.cityRegion} variant="body3">
              {display_region}
            </Typography>
          )}
        </div>
        <Typography variant="caption">{vacancies_number}</Typography>
      </div>
    );
    // return <span>{typeof value === 'string' ? value : value.name}</span>;
  };

  const onMakeOption = (value: BWorkGrade | BWorkFormat) => {
    return <span>{typeof value === 'string' ? value : value.name}</span>;
  };

  const onRenderValue = (values: BCity[] | BWorkGrade[] | BWorkFormat[]) => {
    return values.map(({ name }) => name).join(', ');
  };

  const citiesSelectFilter = (options: Option<BCity>[], filterValue: string) => {
    return options.filter((option) => option.value.name.includes(filterValue));
  };

  const handleRoleSearch = (isOpen: boolean) => () => {
    setRoleSearch(isOpen ? true : !!roleSearchValue.length);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleSearchValue(e.target.value.toLowerCase());
  };

  const handleSearchClear = () => {
    setRoleSearchValue('');
  };

  return (
    <Card border="default" className={cn(styles.search, className)}>
      <div className={styles.additionalFilters}>
        <Select
          applicationRootElementID={rootElementId}
          className={styles.searchInput}
          dropdownClassName={styles.selectDropdown}
          filter
          filterFunction={citiesSelectFilter}
          isShowCountOfValues={selectedCities.length > 1}
          label="Город"
          makeOption={onMakeOptionCity}
          multiple
          onChange={handleSelectChange('cities')}
          options={cities}
          renderValue={onRenderValue}
          selectAll
          selectAllText="Все города"
          values={selectedCities}
        />
        <Select
          applicationRootElementID={rootElementId}
          className={styles.searchInput}
          label="Опыт работы"
          makeOption={onMakeOption}
          multiple
          onChange={handleSelectChange('grades')}
          options={workGrade}
          renderValue={onRenderValue}
          values={selectedGrades}
        />
        <Select
          applicationRootElementID={rootElementId}
          className={styles.searchInput}
          label="Формат работы"
          makeOption={onMakeOption}
          multiple
          onChange={handleSelectChange('format')}
          options={workFormats}
          renderValue={onRenderValue}
          values={selectedWorkFormat}
        />
      </div>

      <Checkbox
        checked={isInternship}
        className={styles.checkbox}
        label="Стажировка"
        onChange={handleCheckboxChange('internship')}
      />

      {roleSearch ? (
        <Search
          autoFocus
          fullWidth
          onBlur={handleRoleSearch(false)}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
          size="small"
          value={roleSearchValue}
        />
      ) : (
        <div className={styles.navigation}>
          <Typography variant="subtitle1">Направления</Typography>
          <Icon className={styles.icon} iconName={Icons.Search} onClick={handleRoleSearch(true)} size="large" />
        </div>
      )}

      {directions.length > 0 && (
        <CheckboxTree
          directions={directions}
          onChange={handleCheckboxTreeChange}
          searchValue={roleSearchValue}
          values={selectedRoles.map(({ role_id: roleId }) => roleId)}
        />
      )}

      <Checkbox
        checked={isDisability}
        className={styles.checkbox}
        label="Для людей с ограниченными возможностями здоровья"
        onChange={handleCheckboxChange('disability')}
      />

      <Button
        className={styles.clearButton}
        disabled={disableReset}
        fullWidth
        onClick={handleResetFilters}
        size="medium"
        variant="plain"
      >
        Сбросить фильтры
      </Button>
    </Card>
  );
};
