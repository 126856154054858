import { Skeleton } from '@beeline/design-system-react';

import styles from './styles.module.scss';

const animated = true;
const blocks = [[...Array(4).keys()], [...Array(5).keys()], [...Array(4).keys()]];

/** @description Скелетон описания вакансии */
export const DescriptionInfoSkeleton = () => {
  return (
    <div className={styles.descriptionInfo}>
      {blocks.map((block, index) => (
        // eslint-disable-next-line sonarjs/no-array-index-key
        <div key={index} className={styles.block}>
          <Skeleton animated={animated} className={styles.blockTitle} height={32} radius={12} />
          {block.map((value) => (
            <div key={value} className={styles.blockRow}>
              <Skeleton animated={animated} height={22} variant="circle" />
              <Skeleton animated={animated} height={22} radius={12} />
            </div>
          ))}
        </div>
      ))}
      <div className={styles.stackBlock}>
        <Skeleton animated={animated} height={32} radius={12} width={74} />
        <div className={styles.stackRow}>
          <Skeleton animated={animated} height={32} radius={12} width={60} />
          <Skeleton animated={animated} height={32} radius={12} width={60} />
          <Skeleton animated={animated} height={32} radius={12} width={60} />
        </div>
      </div>
    </div>
  );
};
