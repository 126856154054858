import { Avatar, Button, ThemeProvider, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';
import { useVariant } from './hook';
import { cards } from './constants';

import { PageContainer } from ':components';

/** Компонент "Программа стажировки" */
export const InternProgram = () => {
  const { cardSubtitleVariant, cardTitleVariant, titleVariant } = useVariant();
  return (
    <PageContainer className={styles.internProgram}>
      <div className={styles.titleRow}>
        <Typography className={styles.title} variant={titleVariant}>
          Программа стажировки
        </Typography>
        <Button className={styles.button} size="large" variant="contained">
          Откликнуться
        </Button>
      </div>
      <div className={styles.cards}>
        {cards.map((card) => (
          <div key={card.title} className={styles.card}>
            <Typography variant={cardTitleVariant}>{card.title}</Typography>
            {card.icon && (
              <ThemeProvider className={styles.avatarTheme} theme="dark">
                <Avatar className={styles.avatar} iconName={card.icon} variant="circle" />
              </ThemeProvider>
            )}
            <Typography className={styles.cardSubtitle} variant={cardSubtitleVariant}>
              {card.subtitle}
            </Typography>
          </div>
        ))}
      </div>
    </PageContainer>
  );
};
