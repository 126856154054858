import { Button, Icon, Typography, TypographyVariant } from '@beeline/design-system-react';
import { FC } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import styles from './styles.module.scss';

import { useVariantUp, VariantConfig } from ':hooks';

interface Props {
  /** Обработчик нажатия на кнопку назад */
  onClose: () => void;
  /** Заголовок */
  title: string;
}

const variantConfig: VariantConfig<{
  titleVariant: TypographyVariant;
}> = {
  titleVariant: ['h4', 'h2', 'h2', 'h1'],
};

/**
 * @description Компонент отображения заголовка
 */
export const VacancyFormHeader: FC<Props> = ({ title, onClose: handleClose }) => {
  const { titleVariant } = useVariantUp(variantConfig);

  return (
    <div className={styles.vacancyFormHeader}>
      <Button onClick={handleClose} startIcon={<Icon iconName={Icons.ArrowLeft} size="small" />} variant="plain">
        Вернуться к вакансии
      </Button>

      <Typography className={styles.text} variant={titleVariant}>
        {title}
      </Typography>
    </div>
  );
};
