import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import { WorkIsCard } from ':types';

export const works: WorkIsCard[] = [
  {
    title: 'Обучение и гибкий формат',
    subtitleList: [
      'Наставник поможет расти, поддержит и даст рекомендации по улучшению работы ',
      'Нагрузка от 10 до 40 часов в неделю',
      'Гибридный или дистанционный формат',
    ],
  },
  {
    icon: Icons.Tasks,
    title: 'Реальные задачи',
    subtitleList: [
      'Работа над сервисами билайна, много практики в своей области с опытными специалистами среди лидеров индустрии',
    ],
  },
  {
    icon: Icons.Certificate,
    title: 'Практика для вуза',
    subtitleList: [
      'Мы оформим необходимые документы для вуза о прохождении производственной или преддипломной практики',
    ],
  },
  {
    icon: Icons.Ruble,
    title: 'Оплачиваемая стажировка',
    subtitleList: ['Стажировка оплачивается в соответствии с количеством отработанных часов'],
  },
  {
    icon: Icons.Magic,
    title: 'Работа в билайне',
    subtitleList: ['Понравится работа на стажировке и у нас будет подходящая вакансия, останетесь в команде'],
  },
];
