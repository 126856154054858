import { FC, useState } from 'react';
import { Card, Chip, Icon, Typography, Button } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { Link } from 'react-router-dom';
import cn from 'clsx';
import { colorTextInactive } from '@beeline/design-tokens/js/tokens/globals/colors';

import styles from './styles.module.scss';
import { displayCity } from './utils';
import { useVariant } from './hooks';

import { useMobileView } from ':hooks';
import { ListItem } from ':components';

type VacanciesCardProps = {
  city: string[];
  grade: string;
  gross_salary_from: string;
  href: string;
  name: string;
  role: string;
  work_format: string;
};
/**
 * @description Карточка вакансии
 */
export const VacancyCard: FC<VacanciesCardProps> = ({
  name,
  work_format,
  gross_salary_from,
  href,
  grade,
  role,
  city: cities,
}) => {
  const { isNormalViewUp } = useMobileView();
  const [cardHover, setCardHover] = useState(false);
  const { grossVariant, tagVariant } = useVariant();

  const handleSetHover = (isHover: boolean) => () => setCardHover(isHover);

  // TODO вынести скелетон
  // if (loading) {
  //   return (
  //     <Link className={styles.link} to={href}>
  //       <Card className={styles.card} elevation="low">
  //         <div className={styles.cardTitle}>
  //           <Skeleton height={32} radius={12} width={383} />
  //           <Skeleton height={32} radius={12} width={32} />
  //         </div>

  //         <div className={styles.skeletonBullets}>
  //           <Skeleton height={32} radius={12} width={120} />
  //           <Skeleton height={32} radius={12} width={120} />
  //           <Skeleton height={32} radius={12} width={120} />
  //           <Skeleton height={32} radius={12} width={120} />
  //         </div>
  //       </Card>
  //     </Link>
  //   );
  // }

  const noGross = gross_salary_from === '0';
  const city = displayCity(cities);

  return (
    <Link className={styles.link} to={href}>
      <Card
        className={styles.card}
        elevation={cardHover ? 'medium' : 'low'}
        onMouseEnter={handleSetHover(true)}
        onMouseLeave={handleSetHover(false)}
      >
        <div className={styles.cardTitle}>
          <Chip className={styles.chip} label={role} />
          <Typography variant="h4">{name}</Typography>
          <Typography className={cn(styles.price, noGross && styles.priceNo)} variant={grossVariant}>
            {noGross ? '₽ на собеседовании' : `${gross_salary_from} ₽`}
          </Typography>
        </div>

        <div className={styles.tags}>
          <div className={styles.tagRow}>
            <div className={styles.tag}>
              {isNormalViewUp && <Icon className={styles.icon} iconName={Icons.Map} />}
              <ListItem color={colorTextInactive} hideIcon text={work_format} variant={tagVariant} />
            </div>

            <div className={styles.tag}>
              {isNormalViewUp && <Icon className={styles.icon} iconName={Icons.Suitcase} />}
              <ListItem
                className={styles.tagDot}
                color={colorTextInactive}
                hideIcon={!work_format || isNormalViewUp}
                text={grade}
                variant={tagVariant}
              />
            </div>
          </div>
          {city && (
            <div className={styles.tagRow}>
              <div className={styles.tag}>
                {isNormalViewUp && <Icon className={styles.icon} iconName={Icons.Pin} />}
                <ListItem color={colorTextInactive} hideIcon text={city} variant={tagVariant} />
              </div>
            </div>
          )}
        </div>

        <Button className={styles.button} variant="contained">
          Посмотреть вакансию
        </Button>
      </Card>
    </Link>
  );
};
