import { FC } from 'react';
import { Button, Icon, Typography } from '@beeline/design-system-react/extracted-styles';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import cn from 'clsx';

import { PageContainer } from ':components/PageContainer';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

interface CardProps {
  /** Иконка */
  icon: Icons;
  /** Описание */
  subtitle: string;
  /** Заголовок */
  title: string;
}

interface Props {
  /** Стиль для карточек */
  cardClassName?: string;
  /** Карточки */
  cards: CardProps[];
  /** Событие клика кнопка */
  onClick?: () => void;
  /** Описание */
  subtitle: string;
  /** Заголовок */
  title: string;
}

/** @description Баннер "Как начать работать в билайн" */
export const HowStart: FC<Props> = ({ cardClassName, cards, onClick: handleClick, subtitle, title }) => {
  const { buttonSize, titleVariant, subtitleVariant, cardTitleVariant, cardSubtitleVariant } = useVariant();

  return (
    <div className={styles.howStart}>
      <PageContainer>
        <div className={styles.content}>
          <div className={styles.headerRow}>
            <div className={styles.header}>
              <Typography className={styles.title} variant={titleVariant}>
                {title}
              </Typography>
              <Typography className={styles.subtitle} variant={subtitleVariant}>
                {subtitle}
              </Typography>
            </div>
            <div>
              <Button className={styles.button} fullWidth onClick={handleClick} size={buttonSize} variant="contained">
                Откликнуться
              </Button>
            </div>
          </div>
          <div className={cn(styles.cards, cardClassName)}>
            {cards.map(({ icon, title: cardTitle, subtitle: cardSubtitle }) => (
              <div key={cardTitle} className={styles.cardContent}>
                <Icon iconName={icon} size="large" />
                <Typography className={styles.cardTitle} variant={cardTitleVariant}>
                  {cardTitle}
                </Typography>
                <Typography className={styles.cardSubtitle} variant={cardSubtitleVariant}>
                  {cardSubtitle}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
