import { FC } from 'react';

import { HowStart } from '../HowStart';

import { cards, puzzleCards } from './constants';
import styles from './styles.module.scss';

import { PuzzleBlocks, PuzzleCard } from ':components';
import { useVariantUp, VariantConfig } from ':hooks';

interface Props {
  onOpen: () => void;
}

const contentVariantConfig: VariantConfig<{
  hideButton: boolean;
}> = {
  hideButton: [true, true, false, false],
};

/** Контент для проф вакансии */
export const ProfContent: FC<Props> = ({ onOpen: handleOpen }) => {
  const { hideButton } = useVariantUp(contentVariantConfig);

  return (
    <>
      <HowStart
        cards={cards}
        onClick={handleOpen}
        subtitle="Простой и понятный процесс найма — каждый кандидат имеет свой личный кабинет,
          в котором отражена информация о вакансии, этапах отбора и документах"
        title="Как начать работать в билайн"
      />
      <PuzzleBlocks
        buttonText="Откликнуться"
        buttonVariant="contained"
        className={styles.puzzleBlocks}
        hideButton={hideButton}
        onButtonClick={handleOpen}
        title="Работа в билайне — это"
      >
        {puzzleCards.map(({ subtitleList, title, icon, image }) => (
          <PuzzleCard
            key={title}
            className={styles.puzzleCard}
            icon={icon}
            image={image}
            subtitleList={subtitleList}
            title={title}
          />
        ))}
      </PuzzleBlocks>
    </>
  );
};
