import { FC } from 'react';
import { Icon, TextArea, TextField, Typography } from '@beeline/design-system-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { contactFields, profFormSchema, ProfVacancyFormType } from ':constants/profSchema';

import * as styles from './styles.module.scss';

import {
  VacancyFormFooter,
  Header,
  VacancyFormHeader,
  PageContainer,
  PhoneInput,
  ResumeBlock,
  SuccessVacancyApply,
} from ':components';
import { useProfResume } from ':hooks';
import { BVacancy } from ':types';

interface Props {
  /** Функция закрытия модального окна */
  onClose: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
}

/**
 * @description Компонент отлика на вакансию проф подбора
 */
export const ProfVacancyForm: FC<Props> = ({ onClose: handleClose, vacancy }) => {
  const { mutateAsync: profResumeMutate, isError, isSuccess } = useProfResume();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { isSubmitSuccessful, errors, isValid },
  } = useForm<ProfVacancyFormType>({
    resolver: zodResolver(profFormSchema),
    mode: 'onBlur',
    defaultValues: {
      phone: '',
      resume_url: '',
    },
  });

  const onSubmit = async (data: ProfVacancyFormType) => {
    profResumeMutate({
      ...data,
      external_system_name: vacancy.external_system_name,
      external_system_vacancy_id: vacancy.external_system_vacancy_id,
    });
  };

  const handleBlockChange = (values: Partial<ProfVacancyFormType>) => {
    Object.entries(values).forEach(([key, value]) => {
      setValue(key as keyof ProfVacancyFormType, value, { shouldValidate: true, shouldDirty: true });
      trigger(key as keyof ProfVacancyFormType);
    });

    // Нужно для сброса ошибки поля "Ссылка на резюме"
    if (values.resume_file) {
      trigger('resume_url');
    }
  };

  if (isSuccess) {
    return <SuccessVacancyApply />;
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Header className={styles.header} />
      <PageContainer className={styles.pageContainer}>
        <VacancyFormHeader onClose={handleClose} title={vacancy.name} />
        <div className={styles.infoBlock}>
          <Typography variant="subtitle1">Контактные данные</Typography>
          <div className={styles.inputs}>
            {contactFields.map(({ name, label, required }) => (
              <TextField
                key={name}
                error={!!errors[name]?.message}
                fullWidth
                helperPosition={errors[name]?.message ? 'block' : 'absolute'}
                helperText={errors[name]?.message}
                label={label}
                {...register(name, { required })}
              />
            ))}
            <div className={styles.phoneField}>
              <PhoneInput errors={errors} onChange={handleBlockChange} />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <Typography variant="subtitle1">Прикрепите резюме или добавьте ссылку*</Typography>
            <ResumeBlock errors={errors} onChange={handleBlockChange} />
          </div>
        </div>
        <div className={styles.additionalInfo}>
          <TextArea
            fullWidth
            helperText="Расскажите о своем опыте и достижениях.
              Добавьте сопроводительное письмо или ссылку на портфолио"
            label="Дополнительная информация"
            {...register('cover_letter')}
          />
          <Typography variant="caption">* Поля обязательные для заполнения</Typography>
        </div>
        {isError && (
          <div className={styles.error}>
            <Icon color="red" contained iconName={Icons.WarningCircled} />
            <Typography className={styles.errorText} variant="body2">
              Не удалось отправить отклик. Попробуйте еще раз
            </Typography>
          </div>
        )}
      </PageContainer>
      <VacancyFormFooter isDisabled={!isValid} isError={isError} isSubmitting={isSubmitSuccessful} />
    </form>
  );
};
