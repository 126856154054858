import { useDirection } from ':hooks';

/**
 * @description Возвращает количество актуальных направлений по вакансиям
 */
export const useDirectionCount = () => {
  const { data: directionsData, isFetching: isDirectionsFetching } = useDirection();
  return !isDirectionsFetching && directionsData
    ? directionsData.reduce((acc, direction) => {
        if (direction.direction_count) {
          return acc + 1;
        }

        return acc;
      }, 0)
    : 0;
};
