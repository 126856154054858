import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@beeline/design-system-react';

import './styles/global.scss';
import { router } from './router';

import { MobileViewWrapper } from ':components';
import { handlers } from ':mocks';
import { useReadyMocks } from ':hooks';

/**
 * @description Главный компонент приложения
 */
export const App: FC = () => {
  const isMocksReady = useReadyMocks(handlers);

  if (!isMocksReady) {
    return null;
  }

  return (
    <ThemeProvider isRoot theme="light">
      <MobileViewWrapper>
        <RouterProvider router={router} />
      </MobileViewWrapper>
    </ThemeProvider>
  );
};
