import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Вариант элемента */
  itemVariant: TypographyVariant;
  /** Вариант заголовка */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      itemVariant: 'body2',
      titleVariant: 'h3',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      itemVariant: 'body2',
      titleVariant: 'h4',
    };
  }

  return {
    itemVariant: 'body3',
    titleVariant: 'h4',
  };
};
