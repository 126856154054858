import { Icon, Typography } from '@beeline/design-system-react';
import { Link } from 'react-router-dom';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import { PageContainer } from ':components/PageContainer';

import * as styles from './styles.module.scss';
import { usefulLinksMock } from './constants';
import { useVariant } from './hooks';

/** Блок стажера "Полезные ссылки" */
export const UsefulLinksBlock = () => {
  const { titleVariant, itemVariant } = useVariant();

  return (
    <PageContainer className={styles.usefulLinks}>
      <Typography className={styles.title} variant={titleVariant}>
        Полезные ссылки
      </Typography>
      <div className={styles.wrapper}>
        {usefulLinksMock.map(({ title, url }) => (
          <Link key={title} className={styles.link} to={url}>
            <Typography variant={itemVariant}>{title}</Typography>
            <Icon iconName={Icons.NavArrowRight} />
          </Link>
        ))}
      </div>
    </PageContainer>
  );
};
