import { useMutation } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BProfResume } from ':types';
import { API } from ':api';

/**
 * @description Отправка резюме по вакансии проф.подбора
 */
export const useProfResume = () => {
  return useMutation({
    mutationKey: ['profResume'],
    mutationFn: (data: BProfResume) => {
      const body = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          body.append(key, value instanceof File ? value : String(value));
        }
      });

      return API.post({
        url: Endpoints.profResume(),
        body,
        responseHandler: null,
        timeout: 10000,
      });
    },
  });
};
