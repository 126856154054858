import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Размер описания */
  descriptionVariant: TypographyVariant;
  /** Размер title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobileViewUp } = useMobileView();

  if (isMobileViewUp) {
    return {
      titleVariant: 'h4',
      descriptionVariant: 'body1',
    };
  }

  return {
    titleVariant: 'h5',
    descriptionVariant: 'body2',
  };
};
