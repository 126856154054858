import { wordForm } from ':utils';

/** Выводит город в нужном формате */
export const displayCity = (cities: string[]) => {
  if (!cities.length) {
    return '';
  }

  if (cities.length === 1) {
    return cities[0];
  }

  const citiesLeft = cities.length - 1;
  return `${cities.sort((a, b) => a.localeCompare(b))[0]}
    и ещё ${citiesLeft} ${wordForm(['город', 'города', 'городов'])(citiesLeft)}`;
};
