import { useMobileView } from ':hooks';

/** Тип для конфига useVariantUp [small, smallplus, normal, large] */
export type VariantConfig<T> = {
  [K in keyof T]: [T[K], T[K], T[K], T[K]];
};

/** Хук для выбора variant для разных размеров экрана [small, smallplus, normal, large] */
export const useVariantUp = <T extends Record<string, any>>(config: VariantConfig<T>): { [K in keyof T]: T[K] } => {
  const { isMobilePlusViewUp, isNormalViewUp, isLargeViewUp } = useMobileView();

  const breakPoints = [true, isMobilePlusViewUp, isNormalViewUp, isLargeViewUp];
  const index = breakPoints.lastIndexOf(true);

  return Object.keys(config).reduce(
    (acc, key) => {
      acc[key as keyof T] = config[key as keyof T][index];
      return acc;
    },
    {} as { [K in keyof T]: T[K] },
  );
};
