import { MaskField } from '@beeline/design-system-react';
import { FC, FocusEvent } from 'react';
import { FieldErrors } from 'react-hook-form';

import { InternFormType } from ':constants/internSchema';

interface Props {
  /** Ошибки валидации */
  errors?: FieldErrors<InternFormType>;
  /** Событие изменения значения после окончания ввода */
  onChange: (value: Partial<InternFormType>) => void;
  /** Индекс табуляции */
  tabIndex?: number;
}

/** Компонент для ввода номерра телефона (MaskField не умеет работать с register из react-hook-form) */
export const PhoneInput: FC<Props> = ({ onChange, tabIndex, errors }) => {
  const error = errors?.phone?.message;

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onChange({ phone: e.target.value });
  };

  return (
    <MaskField
      error={!!error}
      fullWidth
      helperPosition="block"
      helperText={error || 'Мы отправим смс для подтверждения отклика'}
      label="Номер телефона*"
      maskConfig={{
        mask: '+{7}(000)000-00-00',
      }}
      onBlur={handleBlur}
      tabIndex={tabIndex}
    />
  );
};
