import { FC } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { Footer, Header } from ':components';

/**
 *
 * @description Основной лейаут для сайта
 */
export const MainLayout: FC = () => {
  return (
    <>
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </>
  );
};
