import { delay, http, HttpResponse } from 'msw';

import { CitiesMock } from './cities';
import { VacanciesPageMock } from './vacancies';
import { DirectionsMock } from './directions';
import { InternVacancyMock, MassVacancyMock, ProfVacancyMock } from './vacancy';
import { GradeMock } from './grade';
import { WorkFormatMock } from './workFormat';

import { isInternVacancy, isMassVacancy, isProfVacancy } from ':utils';
import { Endpoints } from ':constants';
import { BCity, BDirection, BVacancy, BVacancyPage } from ':types';

/**
 * @description Обработчики запросов для msw
 */
export const handlers = [
  http.get(`${Endpoints.vacancies()}`, async () => {
    await delay();
    return HttpResponse.json<BVacancyPage>(VacanciesPageMock, { status: 200 });
  }),

  http.get(Endpoints.vacancy(':id'), async ({ params }) => {
    await delay();

    const status = 200;
    const { id } = params;
    const jobTypeId = VacanciesPageMock.results.find((vac) => vac.id === id)?.job_type_id || '1';
    if (isProfVacancy(jobTypeId)) {
      return HttpResponse.json<BVacancy>(ProfVacancyMock, { status });
    }

    if (isInternVacancy(jobTypeId)) {
      return HttpResponse.json<BVacancy>(InternVacancyMock, { status });
    }

    if (isMassVacancy(jobTypeId)) {
      return HttpResponse.json<BVacancy>(MassVacancyMock, { status });
    }

    return HttpResponse.json<BVacancy>(ProfVacancyMock, { status });
  }),

  http.get(Endpoints.cities(), async () => {
    await delay();
    return HttpResponse.json<BCity[]>(CitiesMock, { status: 200 });
  }),

  http.get(Endpoints.directions(), async () => {
    await delay();
    return HttpResponse.json<BDirection[]>(DirectionsMock, { status: 200 });
  }),

  http.post(Endpoints.massResume(), async () => {
    await delay();
    return HttpResponse.json({}, { status: 200 });
  }),

  http.post(Endpoints.profResume(), async () => {
    await delay();
    return HttpResponse.json({}, { status: 200 });
  }),

  http.get(Endpoints.grade(), async () => {
    await delay();
    return HttpResponse.json(GradeMock, { status: 200 });
  }),

  http.get(Endpoints.workFormat(), async () => {
    await delay();
    return HttpResponse.json(WorkFormatMock, { status: 200 });
  }),
];
