import { Icons } from '@beeline/design-tokens/js/iconfont';

import BeelineTeam from ':assets/img/BeelineTeam.png';

/** Карточки для "Как начать работать в билайн" */
export const cards = [
  {
    icon: Icons.Mail,
    title: 'Отклик',
    subtitle: `Оставьте отклик на интересную вам вакансию. Если мы увидим подходящие опыт или навыки, то рекрутер
      свяжется для назначенияинтервью`,
  },
  {
    icon: Icons.Group,
    title: 'Интервью',
    subtitle: `Чтобы попасть в команду, нужно пройти оценку экспертов, как правило, 1–2 интервью. Мы расскажем о
      вакансии, вы зададите вопросы о работе. Возможно тестовое задание`,
  },
  {
    icon: Icons.Badge,
    title: 'Финал',
    subtitle: `Подпишите договор и другие документы и начинайте работать! В билайне вы сможете заранее
      ознакомиться с документами в личном кабинете и подписать их электронной подписью`,
  },
];

/** Карточки для "Работа в билайне - это" */
export const puzzleCards = [
  {
    title: 'Связь с миром',
    subtitleList: [
      'Cкидки на домашний интернет и ТВ',
      'Корпоративная связь',
      '250 Гб в облако билайн',
      'Бесплатная связь для близких',
    ],
    image: BeelineTeam,
  },
  {
    icon: Icons.Group,
    title: 'Быть среди своих',
    subtitleList: [
      'Сообщества по интересам: фитнес, шахматы, спортивные мероприятия, кино',
      'Скидки от партнеров — SkyEng, СберМаркет, Самокат, Lamoda и др',
    ],
  },
  {
    icon: Icons.Book,
    title: 'Учиться и расти',
    subtitleList: [
      'Курсы от билайн университета, коучинг',
      'Программы карьерного развития',
      'Награда «Бриллиантовая пчела» для лучших сотрудникам',
    ],
  },
  {
    icon: Icons.Heart,
    title: 'Заботиться о здоровье и получать поддержу',
    subtitleList: [
      'ДМС со стоматологией, скидки на ДМС для близких, психолог, доплата по больничному листу ',
      'Дополнительно 2 дня отпуска',
    ],
  },
];
