import { useMutation } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { API } from ':api';
import { BInternResume, QueryCallback } from ':types';

/** Запрос на отправку отклика стажера */
export const useInternResume = ({ onError }: QueryCallback = {}) => {
  return useMutation({
    mutationKey: ['internResume'],
    mutationFn: (data: BInternResume) => {
      const body = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          body.append(key, value instanceof File ? value : String(value));
        }
      });

      return API.post({
        url: Endpoints.internResume(),
        body,
        responseHandler: null,
        timeout: 10000,
      });
    },
    onError,
  });
};
