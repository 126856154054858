import { Link } from 'react-router-dom';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { Icon, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';

import { Routes } from ':constants';

/** @description Кнопка "Вернуться в каталог" */
export const ReturnButton = () => {
  return (
    <Link className={styles.returnButton} to={Routes.Vacancies}>
      <Icon iconName={Icons.ArrowLeft} size="large" />
      <Typography variant="subtitle2">Вернуться в каталог</Typography>
    </Link>
  );
};
