import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BFaculty } from ':types';
import { API } from ':api';

/** Запрос факультетов по id университета */
export const useFaculties = (universityId: string | number) => {
  return useQuery<BFaculty>({
    enabled: !!universityId,
    queryKey: ['faculties', universityId],
    queryFn: () => {
      return API.get(Endpoints.faculties(universityId));
    },
    initialData: {},
  });
};
