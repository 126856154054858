import { FC } from 'react';

import { HowStart } from '../HowStart';
import { InternProgram } from '../InternProgram';

import styles from './styles.module.scss';
import { cards, puzzleCards } from './constants';

import { UsefulLinksBlock, PuzzleBlocks, PuzzleCardIntern } from ':components';
import { useVariantUp, VariantConfig } from ':hooks';

interface Props {
  /** Редирект по кнопке  */
  onOpen: () => void;
}

const contentVariantConfig: VariantConfig<{
  hideButton: boolean;
}> = {
  hideButton: [true, true, true, false],
};

/** Контент для вакансии стажера */
export const InternContent: FC<Props> = ({ onOpen: handleOpen }) => {
  const { hideButton } = useVariantUp(contentVariantConfig);

  return (
    <>
      <InternProgram />
      <UsefulLinksBlock />
      <HowStart
        cardClassName={styles.howStartCards}
        cards={cards}
        onClick={handleOpen}
        subtitle="Сроки и этапы отбора на стажировку различаются в зависимости от направления.
          В целом, все этапы занимают до 1 месяца"
        title="Как проходит отбор"
      />
      <PuzzleBlocks
        buttonText="Откликнуться"
        buttonVariant="contained"
        cardsClassName={styles.cards}
        className={styles.puzzleBlocks}
        contentClassNamme={styles.puzzleContent}
        hideButton={hideButton}
        onButtonClick={handleOpen}
        title="Стажировка в билайне — это"
      >
        {puzzleCards.map(({ subtitleList, title, icon, image }) => (
          <PuzzleCardIntern
            key={title}
            icon={icon}
            image={image}
            imageClassName={styles.image}
            subtitleList={subtitleList}
            title={title}
          />
        ))}
      </PuzzleBlocks>
    </>
  );
};
