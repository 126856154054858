import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import DOMPurify from 'dompurify';

import * as styles from './styles.module.scss';
import { MainInfo } from './components/MainInfo';
import { useVariant } from './hooks';
import { ReturnButton } from './components/ReturnButton';
import { InternContent } from './components/InternContent';
import { ProfContent } from './components/ProfContent';

import { InternVacancyForm, PageContainer } from ':components';
import { Routes } from ':constants';
import { isInternVacancy, isMassVacancy, isProfVacancy } from ':utils';
import { useVacancy } from ':hooks';
import { Modal, ProfVacancyForm, ServiceError, VacancySkeleton } from ':components';

/**
 * @description Страница Вакансии
 */
export const Vacancy: FC = () => {
  const [responseFormOpen, setResponseFormOpen] = useState(false);
  const navigate = useNavigate();

  const { id: vacancyId } = useParams<{ id: string }>();
  // TODO Паша при переключении межддду вкладками тригеррится запрос
  const { data: vacancy, isFetching, isError } = useVacancy(vacancyId);

  const { buttonFullWidth, buttonSize, displayButton } = useVariant();

  const handleOpen = () => setResponseFormOpen(true);
  const handleClose = () => setResponseFormOpen(false);

  const handleBack = () => navigate(-1);
  const handleRedirect = () => navigate(Routes.Vacancies);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (isFetching) {
    return <VacancySkeleton />;
  }

  if (!vacancy || isMassVacancy(vacancy.job_type_id) || isError) {
    return <ServiceError onSecondaryClick={handleBack} variant="500" />;
  }

  const renderContent = () => {
    const { job_type_id: jobTypeId } = vacancy;

    if (isProfVacancy(jobTypeId)) {
      return <ProfContent onOpen={handleOpen} />;
    }

    if (isInternVacancy(jobTypeId)) {
      return <InternContent onOpen={handleRedirect} />;
    }

    // пока нет массовки
    return null;
  };

  const renderResponseForm = () => {
    const { job_type_id: jobTypeId } = vacancy;

    if (isProfVacancy(jobTypeId)) {
      return <ProfVacancyForm onClose={handleClose} vacancy={vacancy} />;
    }

    if (isInternVacancy(jobTypeId)) {
      return <InternVacancyForm onClose={handleClose} vacancy={vacancy} />;
    }

    // Пока нет массовки
    return null;
  };

  return (
    <div className={styles.vacancy}>
      <Helmet>
        <title>{`${vacancy.name} — отправь резюме и найди работу в билайн`}</title>
        <meta content={DOMPurify.sanitize(vacancy.description, { ALLOWED_TAGS: [] })} name="description" />
      </Helmet>
      <MainInfo onClick={handleOpen} vacancy={vacancy} />
      <div className={styles.vacancyContent}>
        <PageContainer>
          <div className={styles.descriptionInfo} dangerouslySetInnerHTML={{ __html: vacancy.description }} />
          {displayButton && (
            <div className={styles.button}>
              <Button fullWidth={buttonFullWidth} onClick={handleOpen} size={buttonSize} variant="contained">
                Откликнуться
              </Button>
            </div>
          )}
        </PageContainer>
        {renderContent()}
        <PageContainer>
          <div className={styles.returnButton}>
            <ReturnButton />
            <Button onClick={handleScrollTop} size="small" variant="outlined">
              <Icon iconName={Icons.ArrowUp} size="small" />
            </Button>
          </div>
        </PageContainer>
      </div>
      <Modal className={styles.modal} open={responseFormOpen}>
        {renderResponseForm()}
      </Modal>
    </div>
  );
};
