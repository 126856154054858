import { useNavigate } from 'react-router-dom';

import { workIsCards } from './constants';

import { PuzzleBlocks, PuzzleCard } from ':components';

/** Работа в билайне - это (Главная) */
export const WorkIsBanner = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/vacancies');
  };

  return (
    <PuzzleBlocks buttonText="Стать частью команды" onButtonClick={handleClick} title="Работа в билайне — это">
      {workIsCards.map(({ subtitleList, title, icon, image }) => (
        <PuzzleCard key={title} icon={icon} image={image} subtitleList={subtitleList} title={title} />
      ))}
    </PuzzleBlocks>
  );
};
