import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BInternInfo } from ':types';
import { API } from ':api';

/** Запрос списка вузов по имени */
export const useInternInfo = () => {
  return useQuery<BInternInfo>({
    queryKey: ['internInfo'],
    queryFn: () => {
      return API.get(Endpoints.internInfo());
    },
    initialData: { graduation: {}, schedule: [] },
  });
};
