export const usefulLinksMock = [
  {
    title: 'Подготовка к собеседованию. Как получить желанный оффер',
    url: 'https://beelinenow.ru/articles/podgotovka-k-sobesedovaniyu-kak-poluchit-zhelannyy-offer/',
  },
  {
    title: 'Как правильно составить резюме? Простые, но важные советы',
    url: 'https://beelinenow.ru/articles/kak-pravilno-sostavit-rezyume-prostye-no-vazhnye-sovety/',
  },
  {
    title: 'Секреты эффективного сопроводительного письма: как привлечь внимание работодателя',
    url: 'https://beelinenow.ru/articles/sekrety-effektivnogo-soprovoditelnogo-pisma-kak-privlech-vnimanie-rabotodatelya/',
  },
  {
    title: 'Портфолио для резюме: что показывать потенциальному работодателю?',
    url: 'https://beelinenow.ru/articles/portfolio-dlya-rezyume-chto-pokazyvat-potentsialnomu-rabotodatelyu/',
  },
  {
    title: 'Найти работу после вуза',
    url: 'https://beelinenow.ru/articles/karernye-konsultanty-kto-eto-i-zachem-oni-nuzhny/',
  },
  {
    title: 'Истории успеха в билайне',
    url: 'https://beelinenow.ru/articles/istorii-uspekha-v-bilayn-sotrudniki-rasskazali-o-puti-ot-stazhirovki-do-bolshikh-zadach/',
  },
];
