import { FC, FocusEvent, useState } from 'react';
import { Autocomplete, Option, Select } from '@beeline/design-system-react';
import { FieldErrors } from 'react-hook-form';

import { InternFormType, studyFields } from ':constants/internSchema';

import styles from './styles.module.scss';

import { useDebounce, useFaculties, useUniversity, useInternInfo } from ':hooks';
import { getOptions } from ':utils';

interface Props {
  /** Стиль компонента */
  className?: string;
  /** Ошибки валидаций */
  errors?: FieldErrors<InternFormType>;
  /** Изменение результата заполнения полей */
  onChange: (value: Partial<InternFormType>) => void;
}

/** Отображение данных об обучении (MaskAutocomplete и Select не умеют работать с register из react-hook-form) */
export const StudyBlock: FC<Props> = ({ className, onChange, errors }) => {
  const [universitySearch, setUniversitySearch] = useState('');

  const [graduationValue, setGraduationValue] = useState<Option<number> | null>(null);
  const [scheduleValue, setScheduleValue] = useState<Option<string> | null>(null);
  const [universityValue, setUniversityValue] = useState<Option<string> | null>(null);
  const [facultyValue, setFacultyValue] = useState<Option<string> | null>(null);

  const deboucedUniversitySearch = useDebounce(universitySearch);

  const { data: universityData, isLoading: universityLoading } = useUniversity(deboucedUniversitySearch);
  const { data: facultyData, isLoading: facultyLoading } = useFaculties(universityValue?.id || '');
  const {
    data: { graduation: graduationData, schedule: scheduleData },
  } = useInternInfo();

  const universityDataOptions = getOptions(universityData);
  const facultyDataOptions = getOptions(facultyData);
  const graduationDataOptions = getOptions(graduationData);
  const scheduleDataOptions = scheduleData.map((scheduleItem) => ({
    id: scheduleItem.schedule_id,
    value: scheduleItem.schedule_name,
  }));

  const [universitySchema, facultySchema, graduationSchema, scheduleSchema, scheduleSchemaId] = studyFields;
  const hideDropDown = deboucedUniversitySearch.length < 3;

  const handleUniversityInput = (value: string) => {
    setUniversitySearch(value);
    setUniversityValue(null);
  };

  // TODO нужно ли чистить факультет при выборе университета?
  const handleUniversityChange = (option: Option<string>) => {
    setUniversityValue(option);
    setUniversitySearch(option.value);
    onChange({ [universitySchema.name]: option.value });
  };

  const handleUniversityBlur = (e: FocusEvent<HTMLInputElement>) =>
    onChange({ [universitySchema.name]: e.target.value });

  const handleFacultyChange = (option: Option<string>) => {
    setFacultyValue(option);
    onChange({ [facultySchema.name]: option.value });
  };

  const handleFacultyBlur = (e: FocusEvent<HTMLInputElement>) => onChange({ [facultySchema.name]: e.target.value });

  const handleGraduationChange = ([option]: Option<number>[]) => {
    setGraduationValue(option);
    onChange({ [graduationSchema.name]: option.value.toString() });
  };

  const renderGraduationValue = () => graduationValue?.value.toString() || '';

  const handleScheduleChange = ([option]: Option<string>[]) => {
    setScheduleValue(option);
    onChange({ [scheduleSchemaId.name]: option.id.toString(), [scheduleSchema.name]: option.value });
  };

  const renderScheduleValue = () => scheduleValue?.value || '';

  const renderAutocompleteValue = (option: Option<string>) => option.value;

  const handleRequiredProp = () => {
    /* Функция для заглушки обязательных свойств */
  };

  const {
    university_name: universityError,
    faculty_name: facultyError,
    graduation_name: graduationError,
    schedule_name: scheduleError,
  } = errors || {};

  return (
    <div className={className}>
      <Autocomplete
        dropdownClassName={styles.dropDown}
        error={!!universityError}
        fullWidth
        helperPosition={hideDropDown || universityError ? 'block' : 'absolute'}
        helperText={universityError?.message || (hideDropDown && universitySchema.helperText) || ''}
        hideDropDown={hideDropDown}
        loading={universityLoading}
        onBlur={handleUniversityBlur}
        onChange={handleUniversityChange}
        onInputChange={handleUniversityInput}
        onInputClear={handleRequiredProp}
        options={universityDataOptions}
        placeholder={universitySchema.label}
        renderValue={renderAutocompleteValue}
        type="select"
        value={universityValue}
      />

      <Autocomplete
        disabled={!universitySearch}
        dropdownClassName={styles.dropDown}
        error={!!facultyError}
        fullWidth
        helperPosition={hideDropDown || facultyError ? 'block' : 'absolute'}
        helperText={facultyError?.message || (hideDropDown && facultySchema.helperText) || ''}
        loading={facultyLoading}
        onBlur={handleFacultyBlur}
        onChange={handleFacultyChange}
        onInputChange={handleRequiredProp}
        onInputClear={handleRequiredProp}
        options={facultyDataOptions}
        placeholder={facultySchema.label}
        renderValue={renderAutocompleteValue}
        type="select"
        value={facultyValue}
      />

      <Select
        applicationRootElementID="Modal_JB"
        error={!!graduationError}
        fullWidth
        helperPosition={graduationError ? 'block' : 'absolute'}
        helperText={graduationError?.message}
        label={graduationSchema.label}
        multiple={false}
        onChange={handleGraduationChange}
        options={graduationDataOptions}
        renderValue={renderGraduationValue}
        values={[]}
      />

      <Select
        applicationRootElementID="Modal_JB"
        error={!!scheduleError}
        fullWidth
        helperPosition={scheduleError ? 'block' : 'absolute'}
        helperText={scheduleError?.message}
        label={scheduleSchema.label}
        multiple={false}
        onChange={handleScheduleChange}
        options={scheduleDataOptions}
        renderValue={renderScheduleValue}
        values={[]}
      />
    </div>
  );
};
