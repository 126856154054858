import { FC } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { Avatar, ThemeProvider, Typography, TypographyVariant } from '@beeline/design-system-react';
import { colorTextInactive } from '@beeline/design-tokens/js/tokens/globals/colors';
import cn from 'clsx';

import styles from './styles.module.scss';

import { VariantConfig, useVariantUp } from ':hooks';
import { ListItem } from ':components';

interface CardProps {
  /** Стиль карточки */
  className?: string;
  /** Иконка */
  icon?: Icons;
  /** Картинка */
  image?: string;
  /** Стиль для картинки */
  imageClassName?: string;
  /** Список subtitle */
  subtitleList: string[];
  /** Title */
  title: string;
}

const cardVariantConfig: VariantConfig<{
  hideButton: boolean;
  subtitleVariant: TypographyVariant;
  titleVariant: TypographyVariant;
}> = {
  titleVariant: ['subtitle1', 'h4', 'h4', 'h4'],
  subtitleVariant: ['body3', 'body2', 'body2', 'body2'],
  hideButton: [true, true, false, false],
};

/** Карточка для мозайки */
export const PuzzleCard: FC<CardProps> = ({ className, icon, image, imageClassName, subtitleList, title }) => {
  const { titleVariant, subtitleVariant } = useVariantUp(cardVariantConfig);

  return (
    <div className={cn(styles.puzzleCard, className)}>
      {image && (
        <div className={styles.imageContainer}>
          <img alt="BeelineTeam" className={cn(styles.image, imageClassName)} src={image} />
        </div>
      )}
      <Typography className={styles.cardTitle} variant={titleVariant}>
        {title}
      </Typography>
      {icon && (
        <ThemeProvider className={styles.avatarTheme} theme="dark">
          <Avatar className={styles.avatar} iconName={icon} variant="circle" />
        </ThemeProvider>
      )}
      <div className={styles.cardSubtitle}>
        {subtitleList.map((subtitle) => (
          <ListItem
            key={subtitle}
            className={styles.listItem}
            color={colorTextInactive}
            hideIcon={subtitleList.length === 1}
            text={subtitle}
            variant={subtitleVariant}
          />
        ))}
      </div>
    </div>
  );
};
