import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { OpenVavanciesBlock } from './OpenVavanciesBlock';
import { SearchBlock } from './SearchBlock';
import { EvolutionBlock } from './EvolutionBlock';
import { WhyBeeline } from './WhyBeeline';
import styles from './styles.module.scss';

import { useDirectionCount, useVacancies } from ':hooks';
import { WorkIsBanner } from ':components';

/**
 * @description Компонент главной страницы
 */
export const Home: FC = () => {
  const { data: vacanciesData, isFetching: isVacanciesFetching } = useVacancies({});
  const directionCount = useDirectionCount();

  return (
    <div className={styles.home}>
      <Helmet>
        <title>Постройте карьеру в билайн</title>
        {!isVacanciesFetching && (
          <meta
            content={`Работа в билайн: начало карьеры и опытные специалисты — ${vacanciesData?.pages[0]?.count || 0}
           вакансий по ${directionCount} направлениям.`}
            name="description"
          />
        )}
      </Helmet>
      <SearchBlock />
      <OpenVavanciesBlock />
      <WorkIsBanner />
      <EvolutionBlock />
      <WhyBeeline />
    </div>
  );
};
