/* eslint-disable no-console */
import 'core-js';
import '@beeline/design-system-react/extracted-styles/cjs/index.css';
import '@beeline/design-tokens/css/font-face.css';
import '@beeline/design-tokens/css/iconfont/iconfont.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

import { App } from './App';

import { APP_BUILD_DATE, APP_BUILD_ENV_NAME, APP_COMMIT_HASH, APP_VERSION, IS_TEST_ENV } from ':constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const container = document.getElementById('root');

if (!IS_TEST_ENV) {
  console.group(`Приложение: job-beeline`);
  console.log(`Версия: ${APP_VERSION}`);
  console.log(`Коммит: ${APP_COMMIT_HASH}`);
  console.log(`Окружение: ${APP_BUILD_ENV_NAME}`);
  console.log(`Дата: ${APP_BUILD_DATE}`);
  console.groupEnd();
}

if (!container) {
  throw new Error('Root node not found');
}

const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
);
