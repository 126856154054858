import { Icons } from '@beeline/design-tokens/js/iconfont';

export const cards = [
  {
    title: 'Какая продолжительность стажировки',
    subtitle: 'До 1 года, точные сроки зависят от программы',
    icon: Icons.Calendar,
  },
  {
    title: 'Занятость',
    subtitle: 'От 10 до 40 часов в неделю по договоренности с руководителем',
  },
  {
    title: 'Дата выхода',
    subtitle: 'По договоренности с hr и руководителем после всех этапов отбора',
  },
  {
    title: 'Адаптация в команде',
    subtitle: 'Знакомство со структурой, руководством, командой и образом жизни компании. Прохождение обучающих курсов',
    icon: Icons.Group,
  },
  {
    title: 'Цели',
    subtitle: `У вас будет до 5 целей, у каждой цели есть свой срок.
      Задача — повысить компетенции, поэтому большая часть задач профильная`,
    icon: Icons.CheckCircled,
  },
  {
    title: 'Подведение итогов',
    subtitle: 'Наставник даст рекомендации по улучшению работы',
  },
];
