import { FC } from 'react';
import clsx from 'clsx';
import { Avatar, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import styles from './styles.module.scss';

interface Props {
  /** Подзаголовок */
  caption: string;
  /** Имя класса для верхнего элемента */
  className?: string;
  /** Флаг отключения анимаций */
  disableAnimations?: boolean;
  /** Флаг отображения внутри слайдера */
  embedded?: boolean;
  /** Заголовок */
  name: string;
  /** Событие клика */
  onClick?: (name: string) => () => void;
  /** Флаг выбора */
  selected?: boolean;
  /** Адрес изображения */
  src: string;
  /** Ссылка на виде */
  url: string;
}

/**
 * @description Компонент отображения содержимого слайда
 */
export const SlideContent: FC<Props> = ({
  caption,
  className,
  name,
  src,
  selected,
  disableAnimations,
  embedded,
  onClick: handleClick,
  url,
}) => {
  return (
    <div
      className={clsx(styles.slide, embedded && styles.embedded, className)}
      onClick={handleClick?.(url)}
      role="button"
      tabIndex={0}
    >
      <div
        className={clsx(
          styles.imageWrapper,
          disableAnimations && styles.disableAnimations,
          embedded && styles.embedded,
          selected && styles.selected,
        )}
      >
        <img alt={name} className={styles.image} src={src} />
        <Avatar className={styles.playIcon} iconName={Icons.Play} />
      </div>

      <div
        className={clsx(
          styles.caption,
          disableAnimations && styles.disableAnimations,
          embedded && styles.embedded,
          selected && styles.selected,
        )}
      >
        <Typography variant="h4">{name}</Typography>
        <Typography className={styles.position} variant="body3">
          {caption}
        </Typography>
      </div>
    </div>
  );
};
