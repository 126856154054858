import photo1 from ':assets/img/why_beeline_1.png';
import photo2 from ':assets/img/why_beeline_2.png';
import photo3 from ':assets/img/why_beeline_3.png';
import photo4 from ':assets/img/why_beeline_4.png';
import photo5 from ':assets/img/why_beeline_5.png';

export const sliderItems = [
  {
    image: photo1,
    name: 'Мария',
    caption: 'Ведущий специалист по\xa0адаптации, билайн университет',
    url: 'https://rutube.ru/play/embed/4bb5f9803ce058c15ee50c67928ede68',
  },
  {
    image: photo2,
    name: 'Аркадий',
    caption: 'Ведущий специалист корпоративных продаж',
    url: 'https://rutube.ru/play/embed/f5a4fb9a8079aeba22482282f4827eae',
  },
  {
    image: photo3,
    name: 'Юрий',
    caption: 'Старший бизнес-аналитик',
    url: 'https://rutube.ru/play/embed/1b526785fef4c7e27894735430caffa5',
  },
  {
    image: photo4,
    name: 'Даниил',
    caption: 'Директор магазина',
    url: 'https://rutube.ru/play/embed/989bebae90e2306f156cf219e950dd1e',
  },
  {
    image: photo5,
    name: 'Александр',
    caption: 'Руководитель департамента планирования и\xa0оптимизации сети радиодоступа',
    url: 'https://rutube.ru/play/embed/a7aa3e5b90117e3ec4b68676f0d1e83c',
  },
];
