import { FC } from 'react';
import { Icon, TextArea, TextField, Typography } from '@beeline/design-system-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { VacancyFormFooter, Header, VacancyFormHeader, PageContainer, PhoneInput, SuccessVacancyApply } from '..';
import { contactFields, InternFormType, internFormschema } from ':constants/internSchema';

import styles from './styles.module.scss';

import { BVacancy } from ':types';
import { useInternResume } from ':hooks';
import { ResumeBlock, StudyBlock } from ':components';

interface Props {
  /** Функция закрытия модального окна */
  onClose: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
}

/**
 * @description Компонент отклика на вакансию для стажеров
 */
export const InternVacancyForm: FC<Props> = ({ vacancy, onClose: handleClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid: isFormValid },
  } = useForm<InternFormType>({
    resolver: zodResolver(internFormschema),
    mode: 'onBlur',
    defaultValues: {
      university_name: '',
      faculty_name: '',
      phone: '',
      graduation_name: '',
      schedule_name: '',
      resume_url: '',
      schedule_id: '',
    },
  });

  const { mutateAsync: internResumeMutate, isPending, isError, isSuccess } = useInternResume();

  const onSubmit = async (data: InternFormType) => {
    internResumeMutate({
      ...data,
      external_system_name: vacancy.external_system_name,
      external_system_vacancy_id: vacancy.external_system_vacancy_id,
    });
  };

  if (isSuccess) {
    return <SuccessVacancyApply />;
  }

  const handleBlockChange = (values: Partial<InternFormType>) => {
    Object.entries(values).forEach(([key, value]) => {
      setValue(key as keyof InternFormType, value, { shouldValidate: true, shouldDirty: true });
      trigger(key as keyof InternFormType);
    });

    // Нужно для сброса ошибки поля "Ссылка на резюме"
    if (values.resume_file) {
      trigger('resume_url');
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Header className={styles.header} />
      <PageContainer className={styles.pageContainer}>
        <VacancyFormHeader onClose={handleClose} title={vacancy.name} />
        <div className={styles.infoBlock}>
          <Typography variant="subtitle1">Контактные данные</Typography>
          <div className={styles.inputs}>
            {contactFields.map(({ name, label, required }) => (
              <TextField
                key={name}
                error={!!errors[name]?.message}
                fullWidth
                helperPosition={errors[name]?.message ? 'block' : 'absolute'}
                helperText={errors[name]?.message}
                label={label}
                {...register(name, { required })}
              />
            ))}
            <div className={styles.phoneField}>
              <PhoneInput errors={errors} onChange={handleBlockChange} />
            </div>
          </div>
        </div>
        <div className={styles.infoBlock}>
          <Typography variant="subtitle1">Образование</Typography>
          <StudyBlock className={styles.inputs} errors={errors} onChange={handleBlockChange} />
        </div>
        <div className={styles.infoBlock}>
          <Typography variant="subtitle1">Прикрепите резюме или добавьте ссылку*</Typography>
          <ResumeBlock errors={errors} onChange={handleBlockChange} />
        </div>
        <div className={styles.additionalInfo}>
          <TextArea
            fullWidth
            helperText="Расскажите о своем опыте и достижениях.
              Добавьте сопроводительное письмо или ссылку на портфолио"
            label="Дополнительная информация"
            {...register('cover_letter')}
          />
          <Typography variant="caption">* Поля обязательные для заполнения</Typography>
        </div>
        {isError && (
          <div className={styles.error}>
            <Icon color="red" contained iconName={Icons.WarningCircled} />
            <Typography className={styles.errorText} variant="body2">
              Не удалось отправить отклик. Попробуйте еще раз
            </Typography>
          </div>
        )}
      </PageContainer>
      <VacancyFormFooter isDisabled={!isFormValid} isError={isError} isSubmitting={isPending} />
    </form>
  );
};
