import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BVacancy } from ':types';
import { API } from ':api';

/**
 * @description Получение вакансии по id
 */
export const useVacancy = (id = '') => {
  return useQuery<BVacancy>({
    queryKey: ['vacancy', id],
    queryFn: () => {
      return API.get(Endpoints.vacancy(id));
    },
    enabled: Boolean(id),
  });
};
