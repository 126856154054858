import { createBrowserRouter } from 'react-router-dom';

import { Routes } from ':constants';
import { MainLayout } from ':layouts';
import { Home, Vacancies, Vacancy } from ':pages';

export const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: Routes.Home,
        element: <Home />,
      },
      {
        path: Routes.Vacancies,
        element: <Vacancies />,
      },
      {
        path: Routes.Vacancy,
        element: <Vacancy />,
      },
    ],
  },
]);
