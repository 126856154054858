import { TypographyVariant } from '@beeline/design-system-react/extracted-styles';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Вариант текста карточки */
  cardSubtitleVariant: TypographyVariant;
  /** Вариант заголовка карточки */
  cardTitleVariant: TypographyVariant;
  /** Вариант заголовка */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      cardSubtitleVariant: 'body2',
      cardTitleVariant: 'h4',
      titleVariant: 'h3',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      cardSubtitleVariant: 'body3',
      cardTitleVariant: 'subtitle1',
      titleVariant: 'h4',
    };
  }

  return {
    cardSubtitleVariant: 'body3',
    cardTitleVariant: 'subtitle1',
    titleVariant: 'h4',
  };
};
