import { FC, PropsWithChildren } from 'react';
import { Button, Typography, TypographyVariant } from '@beeline/design-system-react';
import { ButtonColorVariants } from '@beeline/design-system-react/types/components/Button/Button.types';
import cn from 'clsx';

import styles from './styles.module.scss';

import { VariantConfig, useVariantUp } from ':hooks';
import { PageContainer } from ':components';

interface Props {
  /** Текст кнопки */
  buttonText: string;
  /** Вариант кнопки */
  buttonVariant?: ButtonColorVariants;
  /** Стиль для контейнера карточек */
  cardsClassName?: string;
  /** Стиль для блока */
  className?: string;
  /** Стиль для контента */
  contentClassNamme?: string;
  /** Скрыть кнопку */
  hideButton?: boolean;
  /** Событие клика на кнопку */
  onButtonClick: () => void;
  /** Заголовок */
  title: string;
}

const blockVariantConfig: VariantConfig<{
  titleVariant: TypographyVariant;
}> = {
  titleVariant: ['h4', 'h3', 'h2', 'h2'],
};

/** Контейнер для мозайки с карточками */
export const PuzzleBlocks: FC<PropsWithChildren<Props>> = ({
  buttonText,
  buttonVariant = 'overlay',
  children,
  className,
  cardsClassName,
  contentClassNamme,
  hideButton,
  onButtonClick: handleButtonClick,
  title,
}) => {
  const { titleVariant } = useVariantUp(blockVariantConfig);

  return (
    <div className={cn(styles.puzzleBlocks, className)}>
      <PageContainer>
        <div className={cn(styles.puzzleContent, contentClassNamme)}>
          <Typography className={styles.title} variant={titleVariant}>
            {title}
          </Typography>
          {!hideButton && (
            <Button className={styles.button} onClick={handleButtonClick} size="large" variant={buttonVariant}>
              {buttonText}
            </Button>
          )}
          <div className={cn(styles.cards, cardsClassName)}>{children}</div>
        </div>
      </PageContainer>
    </div>
  );
};
