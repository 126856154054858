import { TypographyVariant } from '@beeline/design-system-react';
import { ButtonSizeVariants } from '@beeline/design-system-react/types/components/Button/Button.types';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Вариант кнопки поиска */
  buttonVariant: ButtonSizeVariants;
  /** Отображение кнопки "Найти" */
  findButton: boolean;
  /** Вариант subtitle */
  subtitleVariant: TypographyVariant;
  /** Вариант тэга */
  tagVariant: TypographyVariant;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp, isLargeViewUp } = useMobileView();

  if (isLargeViewUp) {
    return {
      buttonVariant: 'large',
      findButton: true,
      subtitleVariant: 'body1',
      tagVariant: 'body1',
      titleVariant: 'h1',
    };
  }

  if (isNormalViewUp) {
    return {
      buttonVariant: 'large',
      findButton: true,
      subtitleVariant: 'body2',
      tagVariant: 'body1',
      titleVariant: 'h2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonVariant: 'medium',
      findButton: true,
      subtitleVariant: 'body2',
      tagVariant: 'body2',
      titleVariant: 'h1',
    };
  }

  return {
    buttonVariant: 'medium',
    findButton: false,
    subtitleVariant: 'body2',
    tagVariant: 'body3',
    titleVariant: 'h3',
  };
};
