import { FC, PropsWithChildren } from 'react';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** Стили */
  className?: string;
}

/** Обёртка для блоков страницы под дизайн контента */
export const PageContainer: FC<PropsWithChildren<Props>> = ({ className, children }) => {
  return <div className={cn(styles.pageContainer, className)}>{children}</div>;
};
