import { APP_API_URL } from '.';

/**
 * @description Эндпоинты JobBeeline
 */
export const Endpoints = {
  cities: () => `${APP_API_URL}/api/v1/cities/`,
  directions: () => `${APP_API_URL}/api/v1/roles/`,
  grade: () => `${APP_API_URL}/api/v1/grade/`,
  internResume: () => `${APP_API_URL}/api/v1/intership-resume/`,
  massResume: () => `${APP_API_URL}/api/v1/mass-resume/`,
  presearch: () => `${APP_API_URL}/api/v1/presearch`,
  profResume: () => `${APP_API_URL}/api/v1/professional-resume/`,
  vacancies: () => `${APP_API_URL}/api/v1/vacancies/`,
  vacancy: (id: string) => `${APP_API_URL}/api/v1/vacancies/${id}`,
  workFormat: () => `${APP_API_URL}/api/v1/work-format/`,
  workType: () => `${APP_API_URL}/api/v1/work-type/`,
  university: () => `${APP_API_URL}/api/v1/university/`,
  internInfo: () => `${APP_API_URL}/api/v1/intern-info/`,
  faculties: (university_id: string | number) => `${APP_API_URL}/api/v1/faculties/${university_id}`,
};
