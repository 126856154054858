import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Вариант subtitle */
  subtitleVariant: TypographyVariant;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      subtitleVariant: 'subtitle1',
      titleVariant: 'body1',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      subtitleVariant: 'subtitle2',
      titleVariant: 'body2',
    };
  }

  return {
    subtitleVariant: 'subtitle2',
    titleVariant: 'body2',
  };
};
