import { z } from 'zod';

const allowedDomains = ['hh.ru', 'habr.com', 'linkedin.com', 'disk.yandex.ru', 'dropbox.com', 'icloud.com'];

export const profFormSchema = z
  .object({
    // Контакты
    first_name: z.string().min(1, { message: 'Поле является обязательным' }),
    last_name: z.string().min(1, { message: 'Поле является обязательным' }),
    middle_name: z.string().optional(),
    telegram_url: z.string().optional(),
    email: z.string().trim().email('Некорректный email').optional().or(z.literal('')),
    phone: z
      .string()
      .regex(/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/, {
        message: 'Неверный формат телефонного номера',
      })
      .min(8),
    resume_file: z.instanceof(File).optional(),
    resume_url: z
      .string()
      .trim()
      .optional()
      .or(z.literal(''))
      .refine((val) => {
        if (!val) {
          return true;
        }
        try {
          const url = new URL(val);
          return allowedDomains.some((domain) => url.hostname.endsWith(domain));
        } catch {
          return false;
        }
      }, 'Ссылка должна быть на hh, habr, linkedin, disk.yandex, dropbox или icloud'),
    cover_letter: z.string().optional(),
  })
  .refine(
    (data) => {
      return data.resume_url || data.resume_file;
    },
    {
      message: 'Заполните хотя бы одно из полей: Ссылка на резюме или Загрузка резюме',
      path: ['resume_url'],
    },
  );

/** Типы полей формы */
export type ProfVacancyFormType = z.infer<typeof profFormSchema>;

export interface FieldProps {
  helperText?: string;
  label: string;
  name: keyof ProfVacancyFormType;
  required?: boolean;
}

export const contactFields: FieldProps[] = [
  {
    label: 'Фамилия*',
    required: true,
    name: 'last_name',
  },
  {
    label: 'Имя*',
    required: true,
    name: 'first_name',
  },
  {
    label: 'Отчество',
    name: 'middle_name',
  },
  {
    label: 'Ссылка на телеграм',
    name: 'telegram_url',
  },
  {
    label: 'Почта',
    name: 'email',
  },
];
