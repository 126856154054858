import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Вариант gross */
  grossVariant: TypographyVariant;
  /** Вариант tag */
  tagVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      grossVariant: 'subtitle2',
      tagVariant: 'body2',
    };
  }

  return {
    grossVariant: 'subtitle3',
    tagVariant: 'body3',
  };
};
