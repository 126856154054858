import { Icons } from '@beeline/design-tokens/js/iconfont';

import IntershipImage from ':assets/img/internship.png';

/** Карточки "Как проходит отбор" */
export const cards = [
  {
    icon: Icons.Mail,
    title: 'Отклик',
    subtitle: 'Выбери вакансию, которая подходит по направлению, и заполни отклик',
  },
  {
    icon: Icons.Group,
    title: 'Интервью c hr и руководителем ',
    subtitle: 'Расскажи о мотивации,  профессиональных интересах и опыте',
  },
  {
    icon: Icons.Notes,
    title: 'Тестовое задание',
    subtitle: 'На некоторых позициях есть тестовое задание — это отличная возможность проявить себя',
  },
  {
    icon: Icons.Badge,
    title: 'Финал',
    subtitle: 'Подпиши договор в личном кабинете или в офисе — и начинай стажироваться!',
  },
];

/** Карточки "Стажировка в билайне - это" */
export const puzzleCards = [
  {
    title: 'Обучение и гибкий формат',
    subtitleList: [
      'Опытный наставник составит план обучения, чтобы сделать твою стажировку максимально эффективной',
      'Нагрузка от 10 до 40 часов в неделю — сам выбраешь удобный график',
      'Гибридный или дистанционный формат',
    ],
    image: IntershipImage,
  },
  {
    icon: Icons.Tasks,
    title: 'Реальные задачи',
    subtitleList: [
      `Ты получишь много практики в своей сфере, работая над проектами
      и сервисами билайна вместе с опытными специалистами`,
    ],
  },
  {
    icon: Icons.Certificate,
    title: 'Практика для вуза',
    subtitleList: [
      'Мы оформим необходимые документы для вуза о прохождении производственной или преддипломной практики',
    ],
  },
  {
    icon: Icons.Ruble,
    title: 'Оплачиваемая стажировка',
    subtitleList: ['Стажировка оплачивается в соответствии с количеством отработанных часов'],
  },
  {
    icon: Icons.Magic,
    title: 'Работа в билайне',
    subtitleList: ['Возможность постоянной работы после завершения стажировки при наличии открытых вакансий'],
  },
];
