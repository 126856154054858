import { ChangeEvent, FC, useState } from 'react';
import { Checkbox, Divider, ProgressButton, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';

import { useMobileView } from ':hooks';

interface Props {
  /** Заблокирована ли кнопка */
  isDisabled?: boolean;
  /** Ошибка отправки */
  isError?: boolean;
  /** Форма в статусе отправки или нет */
  isSubmitting?: boolean;
}

/**
 * @description Футер с кнопками и чекбоксом для формы отклика
 */
export const VacancyFormFooter: FC<Props> = ({ isSubmitting, isError, isDisabled }) => {
  const { isNormalViewUp } = useMobileView();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(target.checked);
  };

  const checkbox = (
    <div className={styles.checkbox}>
      <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      <Typography className={styles.text} variant="body2">
        <span>
          Даю{' '}
          <a
            className={styles.link}
            href="https://static.beeline.ru/upload/images/Documents/soglasie_na_dobavlenie_v_kadrovyj_rezerv.pdf"
          >
            согласие
          </a>{' '}
          на обработку персональных данных и включение в кадровый резерв в соответствии с{' '}
          <a className={styles.link} href="https://static.beeline.ru/upload/images/Documents/politica.pdf">
            Политикой обработки персональных данных
          </a>{' '}
          и{' '}
          <a className={styles.link} href="https://static.beeline.ru/upload/images/soglasie_soiskatelya_potok.pdf">
            Положением О кадровом резерве
          </a>
        </span>
      </Typography>
    </div>
  );

  const buttonState = (isError && 'error') || (isSubmitting && 'loading') || 'default';

  if (isNormalViewUp) {
    return (
      <div className={styles.applicationFooter}>
        <Divider />

        <div className={styles.controls}>
          {checkbox}
          <ProgressButton
            disabled={!isChecked || isDisabled}
            size="large"
            state={buttonState}
            type="submit"
            variant="contained"
          >
            Отправить отклик
          </ProgressButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.checkboxWrapper}>{checkbox}</div>
      <div className={styles.stickyButton}>
        <Divider />
        <div className={styles.button}>
          <ProgressButton
            disabled={!isChecked || isDisabled}
            fullWidth
            size="large"
            state={buttonState}
            type="submit"
            variant="contained"
          >
            Откликнуться
          </ProgressButton>
        </div>
      </div>
    </>
  );
};
