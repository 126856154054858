import { ChangeEvent, FC, useRef, useState } from 'react';
import { Button, FileUploaderListItem, Typography, Icon, TextField } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { FieldErrors } from 'react-hook-form';

import { InternFormType, ResumeFields } from ':constants/internSchema';

import styles from './styles.module.scss';

interface Props {
  /** Ошибки валидации формы */
  errors?: FieldErrors<InternFormType>;
  /** Событие изменения значения */
  onChange: (value: Partial<InternFormType>) => void;
}

/** Блок резюме, приложить файл или ссылку */
export const ResumeBlock: FC<Props> = ({ errors, onChange }) => {
  const [resumeFile, setResumeFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [resumeFileSchema, resumeUrlSchema] = ResumeFields;

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    onChange({ [resumeFileSchema.name]: file });
    setResumeFile(file);
  };

  const handleRemoveFilte = () => {
    onChange({ [resumeFileSchema.name]: undefined });
    setResumeFile(undefined);
  };

  const handleUrlBlur = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ [resumeUrlSchema.name]: e.target.value });
  };

  return (
    <div className={styles.resumeBlock}>
      <div className={styles.selectResume}>
        <Button
          className={styles.button}
          disabled={!!resumeFile}
          onClick={handleButtonClick}
          size="medium"
          startIcon={<Icon iconName={Icons.Attachment} />}
        >
          {resumeFileSchema.label}
        </Button>
        <Typography variant="caption">{resumeFileSchema.helperText}</Typography>
      </div>

      {resumeFile && (
        <FileUploaderListItem
          className={styles.fileListUploader}
          iconName={Icons.AddRowDown}
          name={resumeFile.name}
          onRemove={handleRemoveFilte}
          type="pdf"
        />
      )}

      <input
        ref={fileInputRef}
        accept=".pdf, .docx, .rtf"
        hidden
        id="fileInput"
        onChange={handleFileInputChange}
        type="file"
      />

      <TextField
        error={!!errors?.resume_url}
        fullWidth
        helperPosition={errors?.resume_url ? 'block' : 'absolute'}
        helperText={errors?.resume_url?.message}
        label={resumeUrlSchema.label}
        onBlur={handleUrlBlur}
      />
    </div>
  );
};
