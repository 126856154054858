import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import * as styles from './styles.module.scss';

import { Routes } from ':constants';
import successIcon from ':assets/svg/box-with-dialog.svg';

/**
 * @description Компонент успешного отклика на вакансию
 */
export const SuccessVacancyApply = () => {
  const navigate = useNavigate();
  const handleGoHome = () => navigate(Routes.Home);
  const handleGoVacancies = () => navigate(Routes.Vacancies);

  return (
    <div className={styles.wrapper}>
      <img alt="Спасибо за отклик!" src={successIcon} />

      <div className={styles.title}>
        <Typography variant="h4">Спасибо за отклик!</Typography>
        <Typography variant="body1">Рекрутер свяжется с вами в ближайшее время</Typography>
      </div>

      <div className={styles.buttons}>
        <Button onClick={handleGoHome} size="medium">
          Перейти на главную
        </Button>
        <Button onClick={handleGoVacancies} size="medium" variant="contained">
          Вернуться к вакансиям
        </Button>
      </div>
    </div>
  );
};
