import { TypographyVariant } from '@beeline/design-system-react';
import { ButtonSizeVariants } from '@beeline/design-system-react/types/components/Button/Button.types';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Размер кнопки */
  buttonSize: ButtonSizeVariants;
  /** Вариант subtitle */
  cardSubtitleVariant: TypographyVariant;
  /** Вариант title */
  cardTitleVariant: TypographyVariant;
  /** Вариант subtitle */
  subtitleVariant: TypographyVariant;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      buttonSize: 'large',
      cardTitleVariant: 'h4',
      cardSubtitleVariant: 'body2',
      titleVariant: 'h3',
      subtitleVariant: 'body2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonSize: 'large',
      cardTitleVariant: 'h4',
      cardSubtitleVariant: 'body2',
      titleVariant: 'h4',
      subtitleVariant: 'body2',
    };
  }

  return {
    buttonSize: 'medium',
    cardTitleVariant: 'subtitle1',
    cardSubtitleVariant: 'body3',
    titleVariant: 'h4',
    subtitleVariant: 'body3',
  };
};
