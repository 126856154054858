import { Icons } from '@beeline/design-tokens/js/iconfont';

import BeelineTeam from ':assets/img/BeelineTeam.png';

export const workIsCards = [
  {
    title: 'Связь с миром',
    subtitleList: [
      'Cкидки на домашний интернет и ТВ',
      'Корпоративная связь',
      '250 Гб в облако билайн',
      'Бесплатная связь для близких',
    ],
    image: BeelineTeam,
  },
  {
    icon: Icons.Group,
    title: 'Быть среди своих',
    subtitleList: [
      'Сообщества по интересам: фитнес, шахматы, спортивные мероприятия, кино',
      'Скидки от партнеров — SkyEng, СберМаркет, Самокат, Lamoda и др',
    ],
  },
  {
    icon: Icons.Book,
    title: 'Учиться и расти',
    subtitleList: [
      'Курсы от билайн университета, коучинг',
      'Программы карьерного развития',
      'Награда «Бриллиантовая пчела» для лучших сотрудникам',
    ],
  },
  {
    icon: Icons.Heart,
    title: 'Заботиться о здоровье и получать поддержу',
    subtitleList: [
      'ДМС со стоматологией, скидки на ДМС для близких, психолог, доплата по больничному листу ',
      'Дополнительно 2 дня отпуска',
    ],
  },
];
