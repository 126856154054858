import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BUniversity } from ':types';
import { API } from ':api';

/** Запрос списка вузов по имени */
export const useUniversity = (name: string = '') => {
  return useQuery<BUniversity>({
    enabled: name.length > 2,
    queryKey: ['university', name],
    queryFn: () => {
      return API.get({
        params: {
          name,
        },
        url: Endpoints.university(),
      });
    },
    initialData: {},
  });
};
