import { ButtonSizeVariants } from '@beeline/design-system-react/types/components/Button/Button.types';
import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks';

interface VariantType {
  /** Полноразмерная кнопка */
  buttonFullWidth: boolean;
  /** Размер кнопки */
  buttonSize: ButtonSizeVariants;
  /** Отображение кнопки */
  displayButton: boolean;
  /** Скрыть кнопку */
  hideButton: boolean;
  /** Вариант заголовка */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      buttonFullWidth: false,
      buttonSize: 'large',
      displayButton: false,
      titleVariant: 'h3',
      hideButton: false,
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonFullWidth: false,
      displayButton: true,
      buttonSize: 'large',
      titleVariant: 'h4',
      hideButton: false,
    };
  }

  return {
    buttonFullWidth: true,
    displayButton: true,
    buttonSize: 'medium',
    titleVariant: 'h4',
    hideButton: true,
  };
};
